import React from 'react';
import { connect } from 'react-redux';
import { Link, PlainRoute } from 'react-router';
import { goBack } from 'react-router-redux';
import { IUser } from './../../../models/user.model';
import { IAppState } from './../../redux/interfaces/IAppState.d';
import * as authActions from './../../redux/modules/auth.module';
import { navigationUtils } from './../../utils/navigation.utils';
import { Menu } from './../menu/menu.component';

interface IHeaderStateProps {
    auth: IUser;
}

interface IHeaderOwnProps {
    routes: PlainRoute[];
    currentRoute: string;
    root: string;
    title: string;
}

class Component extends React.Component<
    IHeaderStateProps & IHeaderOwnProps & IDispatchProps,
    {}
> {
    private _sidenav: M.Sidenav;

    componentDidMount() {
        this._sidenav = (M.Sidenav as any).init(
            document.querySelector('.sidenav')
        );
    }

    componentWillUnmount() {
        this._sidenav.close();
        this._sidenav.destroy();
    }

    render() {
        const { auth, root, currentRoute, title, routes } = this.props;
        const user = auth || ({} as IUser);
        const isAuthenticated = user.id;
        const mainPageTitle = 'TmServer';
        const menu: { link: string; name?: string; icon?: string }[] = [];
        const isParent = navigationUtils.isParent(routes);

        if (!isAuthenticated) {
            menu.push({ link: '/auth/login', name: 'Вход' });
        }
        if (isAuthenticated) {
            menu.push({ link: '/notifications', name: 'События' });
            menu.push({ link: '/transmitters', name: 'Передатчики' });
            menu.push({ link: '/firmwares', name: 'Прошивки' });
            menu.push({ link: '/converters', name: 'Конвертеры' });
            menu.push({
                link: '/account',
                name: user.username,
                icon: 'account_circle'
            });
        }

        const logout = isAuthenticated ? (
            <li key="logout">
                <form
                    action="/api/v1/auth/logout"
                    method="post"
                    onSubmit={this._handleSubmit.bind(this)}
                >
                    <button type="submit" title="Выход">
                        Выход
                        <i className="material-icons right">exit_to_app</i>
                    </button>
                </form>
            </li>
        ) : null;

        return (
            <nav className="grey darken-4" role="navigation">
                <div className="nav-wrapper container">
                    <Link
                        to={root}
                        id="logo-container"
                        className="brand-logo hide-on-med-and-down"
                    >
                        {mainPageTitle}
                    </Link>
                    <div className="header-title center hide-on-large-only truncate">
                        {title}
                    </div>
                    <Menu
                        key="menu"
                        className="right hide-on-med-and-down"
                        items={menu}
                        currentRoute={currentRoute}
                        afterItems={[logout]}
                    />
                    <Menu
                        key="menu-mobile"
                        id="nav-mobile"
                        className="sidenav"
                        items={menu}
                        currentRoute={currentRoute}
                        beforeItems={[
                            <li key="IndexLink">
                                <Link to={root}>{mainPageTitle}</Link>
                            </li>
                        ]}
                        afterItems={[logout]}
                    />
                    {isParent && (
                        <Link
                            className="sidenav-trigger"
                            to="/#"
                            data-target="nav-mobile"
                        >
                            <i className="material-icons">menu</i>
                        </Link>
                    )}
                    {!isParent && (
                        <Link
                            to="/#"
                            className="navbar-link hide-on-large-only"
                            onClick={this._goBack.bind(this)}
                        >
                            <i className="material-icons">arrow_back</i>
                        </Link>
                    )}
                </div>
            </nav>
        );
    }

    private _handleSubmit(event: React.FormEvent<any>): void {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(authActions.logout());
    }

    private _goBack(event: React.MouseEvent<HTMLAnchorElement>): void {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(goBack());
    }
}

export const HeaderComponent = connect<
    IHeaderStateProps,
    IDispatchProps,
    IHeaderOwnProps
>(
    (state: IAppState) => {
        return {
            auth: state.auth.user
        };
    },
    dispatch => ({ dispatch } as any)
)(Component);
