// tslint:disable:max-line-length
import moment from 'moment-timezone';
import { GaugeTypeEnum } from './../../enums/gauge-type.enum';
import { ProcessStatusEnum } from './../../enums/process-status.enum';
import { IProcess } from './../../models/process.model';

const { TZ } = process.env;

enum SensorUnit {
    c = '°C', // 0
    mm = 'мм', // 1
    percent = '%', // 2
    unknown = '', // 3
    kg = 'кг', // 4
    t = 'т', // 5
    h = 'Н', // 6
    kh = 'кН', // 7
    mv = 'мВ', // 8
    kpa = 'кПа', // 9
    mpa = 'МПа', // 10
    kom = 'кОм', // 11
    degree = '°' // 12
}

export class ProcessUtils {
    static formatName(process: IProcess): string {
        const startTime =
            process && process.startTime
                ? moment(process.startTime).format('DD.MM.YYYY HH:mm:ss')
                : '—';
        const stopTime =
            process && process.stopTime
                ? moment(process.stopTime).format('DD.MM.YYYY HH:mm:ss')
                : '—';

        return `${startTime} - ${stopTime}`;
    }

    static formatFileName(process: IProcess): string {
        return moment.tz(process.startTime, TZ).format('YYMMDD_HHmm');
    }

    static formatFullName(process: IProcess): string {
        const template = 'YYMMDD_HHmm';
        const startTime =
            process && process.startTime
                ? moment(process.startTime).format(template)
                : '—';
        const stopTime =
            process && process.stopTime
                ? moment(process.stopTime).format(template)
                : '—';

        return `${startTime}-${stopTime}`;
    }

    static sensorTypeFormat(sensorType: number): string {
        let result = '';
        switch (sensorType) {
            // 49 SHT-10 (точка росы)
            case GaugeTypeEnum.GT_SHT1_DP:
            case GaugeTypeEnum.GT_R4A:
            case GaugeTypeEnum.GT_R4B:
            case GaugeTypeEnum.GT_R4C:
            case GaugeTypeEnum.GT_R4D:
            case GaugeTypeEnum.GT_R4E:
            case GaugeTypeEnum.GT_R4F:
                break;

            // Датчики теплового потока
            case GaugeTypeEnum.GT_HeatFlowPeltje:
            case GaugeTypeEnum.GT_HeatFlowPeltje48:
            case GaugeTypeEnum.GT_R7:
                break;

            // Датчики давления // 1D
            case GaugeTypeEnum.GT_MPX5700:
            case GaugeTypeEnum.GT_R17:
            case GaugeTypeEnum.GT_R18:
            case GaugeTypeEnum.GT_R19:
            case GaugeTypeEnum.GT_R20:
            case GaugeTypeEnum.GT_R21:
            case GaugeTypeEnum.GT_R22:
            case GaugeTypeEnum.GT_R23:
            case GaugeTypeEnum.GT_R24:
            case GaugeTypeEnum.GT_R25:
                break;

            case GaugeTypeEnum.GT_R32:
                break;

            case GaugeTypeEnum.GT_Tenso_uE2:
            case GaugeTypeEnum.GT_Tenso_uE:
                break;

            // Частотные датчики
            case GaugeTypeEnum.GT_VIMST:
            case GaugeTypeEnum.GT_F0:
            case GaugeTypeEnum.GT_F1:
            case GaugeTypeEnum.GT_F2:
            case GaugeTypeEnum.GT_F3:
            case GaugeTypeEnum.GT_F4:
            case GaugeTypeEnum.GT_F5:
            case GaugeTypeEnum.GT_F6:
            case GaugeTypeEnum.GT_F7:
            case GaugeTypeEnum.GT_VIMS0:
            case GaugeTypeEnum.GT_VIMS1:
            case GaugeTypeEnum.GT_VIMS2:
            case GaugeTypeEnum.GT_VIMS3:
            case GaugeTypeEnum.GT_VIMS4:
            case GaugeTypeEnum.GT_VIMS5:
            case GaugeTypeEnum.GT_VIMS6:
            case GaugeTypeEnum.GT_VIMS7:
                break;

            // Датчики температуры
            case GaugeTypeEnum.GT_DS1820_0:
            case GaugeTypeEnum.GT_DS1820_1:
            case GaugeTypeEnum.GT_DS1820_2:
            case GaugeTypeEnum.GT_DS1820_3:
            case GaugeTypeEnum.GT_HEL700:
            case GaugeTypeEnum.GT_R1:
            case GaugeTypeEnum.GT_R2:
            case GaugeTypeEnum.GT_R3:
            case GaugeTypeEnum.GT_R4:
            case GaugeTypeEnum.GT_TermoHK:
            case GaugeTypeEnum.GT_TermoHKcom:
            case GaugeTypeEnum.GT_TermoHA:
            case GaugeTypeEnum.GT_TermoHAcom:
            case GaugeTypeEnum.GT_Termo48:
            case GaugeTypeEnum.GT_R5:
            case GaugeTypeEnum.GT_R6:
            case GaugeTypeEnum.GT_DS1820_4:
            case GaugeTypeEnum.GT_DS1820_5:
            case GaugeTypeEnum.GT_DS1820_6:
            case GaugeTypeEnum.GT_DS1820_7:
            case GaugeTypeEnum.GT_SHT1_T:
            case GaugeTypeEnum.GT_1820MK_0:
            case GaugeTypeEnum.GT_1820MK_1:
            case GaugeTypeEnum.GT_1820MK_2:
            case GaugeTypeEnum.GT_1820MK_3:
            case GaugeTypeEnum.GT_1820MK_4:
            case GaugeTypeEnum.GT_1820MK_5:
            case GaugeTypeEnum.GT_1820MK_6:
            case GaugeTypeEnum.GT_1820MK_7:
                result = `Температура, ${SensorUnit.c}`;
                break;

            // Датчики перемещения
            case GaugeTypeEnum.GT_MM10:
            case GaugeTypeEnum.GT_MM20:
            case GaugeTypeEnum.GT_MM50:
            case GaugeTypeEnum.GT_Relocate:
            case GaugeTypeEnum.GT_Rel_Ind:
            case GaugeTypeEnum.GT_R26:
            case GaugeTypeEnum.GT_R27:
            case GaugeTypeEnum.GT_R28:
            case GaugeTypeEnum.GT_R29:
            case GaugeTypeEnum.GT_R30:
                result = `Перемещение, ${SensorUnit.mm}`;
                break;

            // SHT-10 (температура, влажность, точка росы)
            case GaugeTypeEnum.GT_SHT1_H_0:
            case GaugeTypeEnum.GT_SHT1_H_1:
            case GaugeTypeEnum.GT_SHT1_H_2:
            case GaugeTypeEnum.GT_SHT1_H_3:
            case GaugeTypeEnum.GT_SHT1_H_4:
            case GaugeTypeEnum.GT_SHT1_H_5:
            case GaugeTypeEnum.GT_SHT1_H_6:
            case GaugeTypeEnum.GT_SHT1_H_7:
            case GaugeTypeEnum.GT_HIH3610:
            case GaugeTypeEnum.GT_R8:
            case GaugeTypeEnum.GT_R9:
            case GaugeTypeEnum.GT_R10:
            case GaugeTypeEnum.GT_R11:
            case GaugeTypeEnum.GT_R12:
            case GaugeTypeEnum.GT_R13:
            case GaugeTypeEnum.GT_R14:
            case GaugeTypeEnum.GT_R15:
            case GaugeTypeEnum.GT_R16:
                result = `Влажность, ${SensorUnit.percent}`;
                break;

            case GaugeTypeEnum.GT_TensoKg:
            case GaugeTypeEnum.GT_TensoKg2:
                result = SensorUnit.kg;
                break;

            case GaugeTypeEnum.GT_TensoT:
            case GaugeTypeEnum.GT_TensoT2:
                result = SensorUnit.t;
                break;

            case GaugeTypeEnum.GT_TensoN:
            case GaugeTypeEnum.GT_TensoN2:
                result = SensorUnit.h;
                break;

            case GaugeTypeEnum.GT_TensoKN:
            case GaugeTypeEnum.GT_TensoKN2:
                result = SensorUnit.kh;
                break;

            case GaugeTypeEnum.GT_U:
            case GaugeTypeEnum.GT_U2V:
                result = SensorUnit.mv;
                break;

            case GaugeTypeEnum.GT_TensoKPa:
            case GaugeTypeEnum.GT_TensoKPa2:
                result = SensorUnit.kpa;
                break;

            case GaugeTypeEnum.GT_TensoMPa:
            case GaugeTypeEnum.GT_TensoMPa2:
                result = SensorUnit.mpa;
                break;

            // Сопротивление, кОм
            case GaugeTypeEnum.GT_R: // 56
            case GaugeTypeEnum.GT_R41:
            case GaugeTypeEnum.GT_R42:
            case GaugeTypeEnum.GT_R43:
            case GaugeTypeEnum.GT_R44:
            case GaugeTypeEnum.GT_R45:
            case GaugeTypeEnum.GT_R46:
            case GaugeTypeEnum.GT_R47:
            case GaugeTypeEnum.GT_R48:
                result = SensorUnit.kom;
                break;

            // Инклинометры
            case GaugeTypeEnum.GT_InclinIN_D3:
            case GaugeTypeEnum.GT_InclinMK_X:
            case GaugeTypeEnum.GT_InclinMK_Y:
            case GaugeTypeEnum.GT_R35:
            case GaugeTypeEnum.GT_R36:
            case GaugeTypeEnum.GT_R37:
            case GaugeTypeEnum.GT_R38:
                result = SensorUnit.degree;
                break;

            default:
                result = 'Неизвестный тип';
                break;
        }
        return result;
    }

    static sensorTypeParse(formatted: string): GaugeTypeEnum {
        switch (formatted) {
            case `Температура, ${SensorUnit.c}`:
                return GaugeTypeEnum.GT_DS1820_0;
            case `Перемещение, ${SensorUnit.mm}`:
                return GaugeTypeEnum.GT_MM10;
            case `Влажность, ${SensorUnit.percent}`:
                return GaugeTypeEnum.GT_SHT1_H_0;
            default:
                throw new Error('неизвестный тип датчика');
        }
    }

    static parsePeriod(valueRaw: string): { amount: number; unit: string } {
        const amount = Number(valueRaw.replace(/\D/gi, ''));
        const unitRaw = valueRaw.replace(/\d/gi, '');
        let unit = '';
        if (unitRaw.indexOf('sec') > -1) {
            unit = 's';
        } else if (unitRaw.indexOf('min') > -1) {
            unit = 'm';
        } else if (unitRaw.indexOf('hour') > -1) {
            unit = 'h';
        } else {
            throw new Error(`Не определен период (${unitRaw})`);
        }

        return { amount, unit };
    }

    static getProcessStatus(process: IProcess): ProcessStatusEnum {
        const period = this.parsePeriod(process.period);
        const now = moment.tz(TZ);
        const startTime = moment.tz(process.startTime, TZ);

        let result = null;
        if (
            now.isBetween(
                startTime
                    .clone()
                    .add(
                        period.amount * (process.point - 1),
                        period.unit as any
                    ),
                startTime
                    .clone()
                    .add(
                        period.amount * (process.point - 0.5),
                        period.unit as any
                    )
            )
        ) {
            result = ProcessStatusEnum.VERY_GOOD;
        } else if (
            now.isBetween(
                startTime
                    .clone()
                    .add(
                        period.amount * (process.point - 0.5),
                        period.unit as any
                    ),
                startTime
                    .clone()
                    .add(period.amount * process.point, period.unit as any)
            )
        ) {
            result = ProcessStatusEnum.GOOD;
        } else if (
            now.isBetween(
                startTime
                    .clone()
                    .add(period.amount * process.point, period.unit as any),
                startTime
                    .clone()
                    .add(
                        period.amount * (process.point + 1),
                        period.unit as any
                    )
            )
        ) {
            result = ProcessStatusEnum.BAD;
        } else {
            result = ProcessStatusEnum.VERY_BAD;
        }
        return result;
    }
}
